<template>
  <div>
    <div v-if="addActiveDisable">
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
          >
          <el-breadcrumb-item>优惠套餐</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button type="success" style="height: 36px" @click="reload"
          ><i style="font-size: 18px" class="el-icon-refresh-right"></i
        ></el-button>
      </div>
      <div class="tableInput">
        <span>日期范围：</span>
        <el-date-picker
          type="date"
          v-model="dateTime.date1"
          style="width: 12%"
        ></el-date-picker>
        <span style="margin: 0 5px">-</span>
        <el-date-picker
          type="date"
          v-model="dateTime.date2"
          style="width: 12%"
        ></el-date-picker>
        <el-select v-model="value" style="width: 8% !important; margin: 0 10px">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          v-model="QueryContent"
          placeholder="请输入内容"
          style="width: 15%; margin-right: 5px"
        ></el-input>
        <el-button type="success" @click="searchTab"
          ><i class="el-icon-search"></i>查询</el-button
        >
      </div>
      <div class="tableBtn">
        <el-button type="primary" @click="addActiveDisable = !addActiveDisable"
          ><i class="el-icon-plus"></i> 发布活动</el-button
        >
        <el-button type="danger" @click="batchDelete"
          ><i class="el-icon-delete"></i> 批量删除</el-button
        >
      </div>
      <div class="table">
        <div style="padding: 10px 0">
          <el-table
            ref="multipleTable"
            border
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%; border: 1px solid #e5e5e5"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" align="center"> </el-table-column>
            <el-table-column prop="id" sortable label="编号" align="center">
            </el-table-column>
            <el-table-column
              prop="create_time"
              sortable
              label="创建日期"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="activity_title" label="名称" align="center">
            </el-table-column>
            <el-table-column
              prop="package_price"
              sortable
              label="活动价"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="activity_inventory"
              sortable
              label="库存"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="activity_type" label="类型" align="center">
            </el-table-column>
            <el-table-column label="状态" align="center" prop="status">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <i
                  class="el-icon-edit poiner"
                  @click="handleEdit(scope.row)"
                ></i>
                <span style="margin: 0 10px"></span>
                <i
                  class="el-icon-delete poiner"
                  @click="handleDelete(scope.row)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
          <div class="tableBottom">
            <span style=""
              >显示第&nbsp;{{
                tableData.length == 0 ? 0 : (page - 1) * length + 1
              }}&nbsp;至&nbsp;{{
                (page - 1) * length + tableData.length
              }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
            >

            <div
              style="
                display: flex;
                justify-content: flex-end;
                align-items: center;
              "
            >
              <el-button @click="homePage">首页</el-button>
              <el-button @click="upPage">上页</el-button>
              <el-pagination
                background
                layout=" pager,slot"
                :total="tableFinshNum"
                :current-page="page"
                @current-change="currentChange"
              ></el-pagination>
              <el-button @click="nextPage">下页</el-button>
              <el-button @click="endPage">末页</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!addActiveDisable">
      <add-active @close="closeAdd" :data="data"></add-active>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    addActive: () => import("./childrens/discountPackage_add.vue"),
  },
  data() {
    return {
      dateTime: { date1: "", date2: "" },
      value: "状态",
      options: [
        {
          value: "待发布",
          label: "待发布",
        },
        {
          value: "进行中",
          label: "进行中",
        },
        {
          value: "已关闭",
          label: "已关闭",
        },
      ],
      QueryContent: "",
      addActiveDisable: true,

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
      page: 1,
      length: 10,
      data: "",
    };
  },
  mounted() {
    this.getTabList();
  },
  computed: {},
  methods: {
    reload() {
      location.reload();
    },

    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/discount_package/del", { id: row.id })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.getTabList();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getTabList() {
      this.$http
        .post("/discount_package/lst", {
          currentPage: this.page,
          currentLength: this.length,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    batchDelete() {
      const idArr = [];
      if (this.multipleSelection.length > 0) {
        this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.multipleSelection.forEach((item) => {
              idArr.push(item.id);
            });
            this.$http
              .post("/discount_package/bacthdel", { idArr })
              .then((res) => {
                if (res.data.status == "success") {
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.getTabList();
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    closeAdd(data) {
      this.addActiveDisable = data;
      this.getTabList();
      this.data = "";
    },
    handleEdit(row) {
      console.log(row);
      this.data = row;
      this.addActiveDisable = false;
    },
    searchTab() {
      this.$http
        .post("/discount_package/search", {
          firstTime: this.dateTime.date1,
          lastTime: this.dateTime.date2,
          status: this.value,
          search: this.QueryContent,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page <= 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page++;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableInput {
  height: 31px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
}

.tableBtn {
  padding: 5px 20px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 14px;
}

.table {
  padding: 20px;

  .tableStatus {
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    padding: 3px;
    font-weight: bold;
  }
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
</style>
